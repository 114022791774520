import React, {
  FC,
  PropsWithChildren,
  createContext,
  memo,
  useCallback,
  useState,
} from 'react';
import { Bars } from 'react-loader-spinner';

import { useQuery } from '@apollo/client';
import { queries } from '@src/adapters';
import { User } from '@src/adapters/me';
import { colors } from '@ui-kit/theme';

export const AuthContext = createContext<{
  isAuthorized: boolean;
  user?: User;
  logout: () => void;
  refetch?: () => void;
}>({
  isAuthorized: false,
  logout: () => {},
  refetch: () => {},
});

export const AuthProvider: FC<PropsWithChildren> = memo(({ children }) => {
  const { data, loading, refetch } = useQuery<{ me: User }>(queries.user.me, {
    fetchPolicy: 'network-only',
  });

  const handleLogout = useCallback(() => {
    localStorage.removeItem('user');
    window.location.href = `${process.env.REACT_APP_AUTH_URL}/logout`;
  }, [refetch]);

  if (loading) {
    return null;
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthorized: !!data,
        user: data?.me,
        logout: handleLogout,
        refetch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
});
