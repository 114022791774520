import React, { FC, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from '@ui-kit//checkbox';
import Button from '@ui-kit/button';
import { Root as Btn } from '@ui-kit/button/button.styles';

import EyePath from '../../assets/images/eye.svg';
import UkassaPath from '../../assets/images/ukassa.svg';
import InfoForPaymentModal from './components/info-for-payment-modal';
import ViewsCard from './components/views-card';
import {
  BoxButtons,
  BoxValue,
  Cards,
  DopText,
  Eye,
  ItemPayInfo,
  ItemPayInfoSumm,
  ItemPaymentInfo,
  Link,
  OneEye,
  PayInfo,
  PayInfoAccept,
  Root,
  Text,
  TextCheckBox,
  ThreeEye,
  Title,
  TwoEye,
  Ukassa,
} from './payment.styles';

interface PaymentProps {}

export const Payment: FC<PaymentProps> = memo(() => {
  const { t, i18n } = useTranslation();
  const defaultValues = [
    {
      views: 500,
      component: <Eye src={EyePath} />,
      description: t('payment.descriptionsCard.personal'),
      value: 0,
    },
    {
      views: 5000,
      component: (
        <TwoEye>
          <Eye src={EyePath} />
          <Eye src={EyePath} />
        </TwoEye>
      ),
      description: t('payment.descriptionsCard.creative'),
      value: 0,
    },
    {
      views: 50000,
      component: (
        <ThreeEye>
          <OneEye src={EyePath} />
          <TwoEye>
            <Eye src={EyePath} />
            <Eye src={EyePath} />
          </TwoEye>
        </ThreeEye>
      ),
      description: t('payment.descriptionsCard.big'),
      value: 0,
    },
  ];
  const [isShowModal, setIsShowModal] = useState(false);
  const [info, setInfo] = useState(defaultValues);

  const [checked, setChecked] = useState(false);

  const minus = (item: any) => {
    setInfo(
      info.map((it) => {
        if (it.views !== item.views) {
          return it;
        } else {
          return { ...it, value: it.value === 0 ? 0 : it.value - 1 };
        }
      })
    );
  };

  const plus = (item: any) => {
    setInfo(
      info.map((it) => {
        if (it.views !== item.views) {
          return it;
        } else {
          return { ...it, value: it.value + 1 };
        }
      })
    );
  };

  const countViews = () => {
    let res = 0;
    info.map((it) => {
      res = res + it.value * it.views;
    });
    return res;
  };

  const handleSubmit = () => {
    fetch(`${process.env.REACT_APP_API_PAYMENT_URL}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({
        amount: countViews(),
        user_id: 1,
        redirect_url: `${process.env.REACT_APP_PAYMENT_REDIRECT}`,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.confirmation.confirmation_url) {
          window.location.replace(data.confirmation.confirmation_url);
        }
      });
  };

  const checkDisableBtn = () => !checked || countViews() === 0;

  return (
    <Root>
      <Title>{t('payment.addBalance')}</Title>

      <Cards>
        {info.map((item) => (
          <ViewsCard
            key={item.views}
            item={item}
            minus={() => minus(item)}
            plus={() => plus(item)}
          />
        ))}
      </Cards>

      <PayInfo>
        <ItemPayInfo>
          <Text>{t('payment.viewsInBasket')}</Text>

          <BoxValue>
            {countViews().toLocaleString('ru')} {t('payment.units')}
          </BoxValue>
        </ItemPayInfo>
        <ItemPayInfoSumm>
          <Text>{t('payment.paySumm')}</Text>
          <BoxValue>
            {countViews().toLocaleString('ru')} {t('payment.rub')}
          </BoxValue>
        </ItemPayInfoSumm>
        <DopText>{t('payment.oneViews')}</DopText>
      </PayInfo>

      <PayInfoAccept>
        <ItemPaymentInfo>
          <Checkbox checked={checked} onChange={() => setChecked(!checked)} />
          <TextCheckBox>
            {t('payment.IAccept')}{' '}
            <Link href="https://google.com">{t('payment.tariff')}</Link>{' '}
            {t('payment.ecosystem')}
          </TextCheckBox>
        </ItemPaymentInfo>
        <BoxButtons>
          <Button
            text={t('payment.bill')}
            disabled={checkDisableBtn()}
            disabledWhite={checkDisableBtn()}
            variant="dark"
            onClick={() => setIsShowModal(true)}
            style={{ with: 225 }}
          />
          <Btn
            variant="dark"
            onClick={handleSubmit}
            disabled={checkDisableBtn()}
            disabledWhite={checkDisableBtn()}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {t('payment.ukassa')}
            <Ukassa src={UkassaPath} />
          </Btn>
        </BoxButtons>
      </PayInfoAccept>

      <InfoForPaymentModal
        count={countViews()}
        summ={countViews()}
        isOpened={isShowModal}
        onClose={() => setIsShowModal(false)}
        onCloseSuccess={() => {
          setIsShowModal(false);
          setInfo(defaultValues);
        }}
      />
    </Root>
  );
});
