import styled, { css } from 'styled-components';

import { animations, colors, mixins, rgba, shape } from '../theme';

export const Root = styled.div``;

export const Container = styled.div`
  border-radius: ${shape.rounding.L};
  ${mixins.flex({})};
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
  height: 460px;
`;

export const ColumnMenu = styled.div<{ count: number; currentIndex: number }>`
  background: ${colors.background.grayStandard};
  width: 340px;
  box-sizing: border-box;
  color: ${colors.text.white};
  border-radius: ${shape.rounding.L};
  margin-right: 14px;
  transition: border-radius ease ${animations.duration};
  max-height: 100%;
  position: relative;
  overflow: hidden;

  ${({ count, currentIndex }) => {
    if (currentIndex === 0) {
      return css`
        border-top-left-radius: 0;
      `;
    }
    if (currentIndex === count - 1) {
      return css`
        border-bottom-left-radius: 0;
      `;
    }
  }};

  &:after {
    content: '';
    width: 100%;
    position: sticky;
    bottom: -32px;
    height: 64px;
    left: -32px;
    display: block;
    background: ${colors.background.grayStandard};
    background: linear-gradient(
      180deg,
      rgba(2, 0, 36, 0) 0%,
      ${colors.background.grayStandard} 100%
    );
  }

  a {
    text-decoration: none;
    color: ${colors.text.orange};
  }
`;

export const ColumnMenuInner = styled.div`
  height: 100%;
  padding: 32px;
  box-sizing: border-box;
  overflow: auto;
  ${mixins.scrolls()};
`;
export const ColumnContentInner = styled.div`
  height: 100%;
  padding: 32px;
  box-sizing: border-box;
  overflow: auto;
  ${mixins.scrolls()};

  ${mixins.flex({
    horizontal: false,
  })};
`;

export const ColumnContent = styled.div`
  position: relative;
  flex: 1;
  height: 100%;
  background: ${colors.background.white};
  overflow: hidden;
  box-sizing: border-box;
  border-radius: ${shape.rounding.L};

  &:after {
    content: '';
    width: 100%;
    position: sticky;
    bottom: -32px;
    height: 64px;
    left: -32px;
    display: block;
    background: ${colors.background.grayStandard};
    background: linear-gradient(
      180deg,
      rgba(2, 0, 36, 0) 0%,
      ${rgba(colors.background.grayStandard, 0.4)} 180%
    );
  }
`;

export const Tab = styled.div<{
  active?: boolean;
  clickable: boolean;
  disabled?: boolean;
}>`
  width: 100%;
  border-bottom-left-radius: ${shape.rounding.L};
  border-top-left-radius: ${shape.rounding.L};
  color: ${colors.text.gray};
  ${mixins.flex({
    horizontalAlignment: 'center',
    verticalAlignment: 'center',
  })};
  background: transparent;
  border-radius: ${shape.rounding.L};
  transition: background ease ${animations.duration},
    border-radius ease ${animations.duration}, color ease ${animations.duration};

  ${({ active }) =>
    active &&
    css`
      background: ${colors.background.grayStandard};
      color: ${colors.accent.yellow};
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    `}

  ${({ clickable,disabled=false }) =>
    clickable && !disabled &&
    css`
      cursor: pointer;
      &:hover {
        // background: ${colors.background.grayDark};
        color: ${colors.accent.yellow};
        // border-bottom-right-radius: 0;
        // border-top-right-radius: 0;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${colors.text.grayExtraLight};
    `}
`;

export const Tabs = styled.div<{ count: number }>`
  width: 60px;
  height: 100%;

  ${Tab} {
    ${({ count }) => css`
      height: calc(100% / ${count});
    `}
  }
`;
