import React, { FC, memo, useCallback, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TailSpin } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { AuthContext } from '@src/context';
import Button from '@ui-kit/button';
import Icons from '@ui-kit/icons';
import { colors } from '@ui-kit/theme';

import {
  AuthFormTitle,
  AuthFormWrapper,
  AuthInput,
  InputMessage,
  InputWrapper,
  Label,
  SocialWrapper,
  Wrapper,
} from '../../home.styles';

interface AuthFormProps {}

interface AuthFormData {
  email: string;
  password: string;
}

const requiredFieldMessage = 'Обязательное поле';

const authFormSchema = yup.object<AuthFormData>({
  email: yup
    .string()
    .email('Некорректный email')
    .required(requiredFieldMessage),
  password: yup.string().required(requiredFieldMessage),
});

export const AuthForm: FC<AuthFormProps> = memo((props) => {
  const { t } = useTranslation();
  const { refetch } = useContext(AuthContext);
  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<AuthFormData>({
    resolver: yupResolver(authFormSchema),
    mode: 'all',
  });

  const handleSubmit = useCallback((data: AuthFormData) => {
    fetch(`${process.env.REACT_APP_AUTH_URL}/signin`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((response) => {
      if (response.status === 200) {
        toast.success('Вы успешно авторизированы', {
          position: toast.POSITION.TOP_RIGHT,
        });
        if (refetch) {
          refetch();
        }
      } else {
        toast.error(
          'Ошибка авторизации. Проверьте правильность веденных данных'
        );
      }
    });
  }, []);

  return (
    <AuthFormWrapper>
      <Wrapper>
        <AuthFormTitle>{t('sign.welcomeBack')}</AuthFormTitle>
        <InputWrapper error={!!errors.email}>
          <AuthInput
            {...register('email')}
            name="email"
            placeholder={t('sign.email')}
          />
          {errors.email && <InputMessage>{errors.email.message}</InputMessage>}
        </InputWrapper>
        <InputWrapper error={!!errors.password}>
          <AuthInput
            {...register('password')}
            name="password"
            placeholder={t('sign.password')}
            type="password"
          />
          {errors.password && (
            <InputMessage>{errors.password.message}</InputMessage>
          )}
        </InputWrapper>
      </Wrapper>
      <Wrapper>
        <Label>{t('sign.signInVia')}</Label>
        <SocialWrapper>
          <a href={`${process.env.REACT_APP_AUTH_URL}/google`}>
            <Icons.Google size={32} />
          </a>
          <a href={`${process.env.REACT_APP_AUTH_URL}/vk`}>
            <Icons.Vk size={32} />
          </a>
        </SocialWrapper>
      </Wrapper>
      <Wrapper>
        {!isSubmitting && (
          <Button
            onClick={onSubmit(handleSubmit)}
            text={t('sign.signIn')}
            variant="dark"
          />
        )}
        {isSubmitting && (
          <TailSpin
            height="32"
            width="32"
            color={colors.background.yellow}
            ariaLabel="loading"
          />
        )}
      </Wrapper>
    </AuthFormWrapper>
  );
});
