import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';
import { queries } from '@src/adapters';
import { DataItem, MyDataItemsResponse } from '@src/adapters/data-item';
import HoverMenu from '@src/components/hover-menu';
import ModalStatistics from '@src/components/modal-statistics';
import QrCode from '@src/components/qr-code';
import { InfoLoading } from '@src/pages/media/media.loading';
import { Label, Row, SubTitle, Value } from '@src/pages/media/media.styles';
import { getInfo } from '@src/pages/media/media.utils';
import Icons from '@ui-kit/icons';
import Table from '@ui-kit/table';
import Thumbnail from '@ui-kit/thumbnail';

import { HoverMenuWrapper, StyledMenuIcon } from '../media/media.styles';
import { Root, Switcher, Title } from './library.styles';

interface CreateArProps {}

enum TABS {
  INFO = 'info',
  QR = 'qr',
}

const map: Record<string, string> = {
  android: 'androidAppURL',
  ios: 'iosAppURL',
  web: 'webAppURL',
};

const getLinkPath = (platform: string) => {
  return map[platform] as
    | 'androidAppURL'
    | 'iosAppURL'
    | 'webAppURL'
    | undefined;
};

export const Library: FC<CreateArProps> = memo(() => {
  const { t } = useTranslation();
  const [showModalStatistics, setShowModalStatistics] = useState<number | null>(
    null
  );

  const [tab, setTab] = useState<string>(TABS.INFO);
  const [selectedItem, setSelectedItem] = useState<DataItem | undefined>();
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(0);

  const { loading: loadingData, data } = useQuery<MyDataItemsResponse>(
    queries.dataItem.getAllItems,
    {
      onCompleted: (data1) => {
        setSelectedItem(
          data1.dataItems.length > 0 ? data1.dataItems[0] : undefined
        );
      },
    }
  );
  const loading = loadingData;
  const items = data?.dataItems ?? [];

  const handleRowClick = useCallback(
    (rowIndex: number) => {
      const selectedItem = items[rowIndex];
      setTab(TABS.INFO);
      setSelectedItemIndex(rowIndex);
      setSelectedItem(selectedItem);
    },
    [items]
  );

  const handleHoverElClick = useCallback(
    (rowIndex: number, type?: string) => {
      const selectedItem = items[rowIndex];
      setTab(TABS.INFO);
      if (type === 'chart') {
        setShowModalStatistics(selectedItem.id);
      }
    },
    [items]
  );

  const infoItems =
    (selectedItem?.contributor?.application && getInfo(selectedItem, t)) || [];
  const info = useMemo(() => {
    switch (tab) {
      case TABS.INFO:
        console.log("selectedItem",selectedItem)
        return (
          <>
            {!loading &&
              infoItems.map((info) => (
                <Row>
                  <Label>{info.label}</Label>
                  <Value>{info.value}</Value>
                </Row>
              ))}
            {loading && <InfoLoading />}
            {!loading && !items.length && <Title>{t('common.notFound')}</Title>}
          </>
        );
      case TABS.QR:
        return (
          <>
            <SubTitle>
              Проверьте данную AR-сцену через инструменты оператора
            </SubTitle>
            {(selectedItem?.lsEntityData || [])
              ?.map((internalItem) => {
               
                const linkPath = getLinkPath(internalItem.platform);
                console.log("linkPath",linkPath)
                if (!linkPath) {
                  return null;
                }
                const link = selectedItem?.contributor?.application?.[linkPath];
                if (!link) {
                  return null;
                }

                return (
                  <Row key={`info-${internalItem.platform}`}>
                    <Label>{internalItem.platform}</Label>
                    <Value>
                      <QrCode value={`${link}/${internalItem.id}`} />
                    </Value>
                  </Row>
                );
              })
              .filter(Boolean)}
          </>
        );
    }
  }, [loading, infoItems, tab]);

  return (
    <Root>
      <Title>{t('common.arSceneInfo')}</Title>

      <Switcher
        tabs={[
          {
            value: TABS.INFO,
            content: <Icons.Clipboard size={32} />,
          },
          {
            value: TABS.QR,
            content: <Icons.Qr size={32} />,
          },
        ]}
        info={info}
        content={
          <>
            {!loading && items && items.length > 0 && (
              <Table
                activeIndex={selectedItemIndex}
                onRowClick={handleRowClick}
                headers={TableHeaders}
                rows={items.map(
                  (
                    { contributor, downloadsCount, thumbnailPath, triggerType },
                    rowI
                  ) => ({
                    columns: [
                      { content: <Thumbnail src={thumbnailPath} /> },
                      { content: contributor?.application?.name || '' },
                      { content: t(`common.scenario.${triggerType}`) },
                      { content: downloadsCount },
                      {
                        content: (
                          <HoverMenuWrapper>
                            <HoverMenu
                              isShowDelete={false}
                              isShowEdit={false}
                              onClick={(type) => handleHoverElClick(rowI, type)}
                            />
                            <StyledMenuIcon size={26} />
                          </HoverMenuWrapper>
                        ),
                      },
                    ],
                  })
                )}
              />
            )}
          </>
        }
        currentTab={tab}
        onTabChange={setTab}
      />
      <ModalStatistics
        id={showModalStatistics}
        onClose={() => setShowModalStatistics(null)}
      />
    </Root>
  );
});

const TableHeaders = {
  columns: [
    { content: undefined },
    { content: 'Оператор' },
    { content: 'Сценарий AR' },
    { content: 'Просмотры' },
  ],
};
