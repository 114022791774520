import { ITEM_TYPE } from '@src/core/types';


export const FormatByType: Record<string, string> = {
    [ITEM_TYPE.IMAGE]: '.jpg,.jpeg,.png',
    [ITEM_TYPE.OBJECT]: '.glb',
    [ITEM_TYPE.VIDEO]: '.mp4',
  };
export const findTypeByFormat = (format: string) => {
    let resultType;
    Object.keys(FormatByType).forEach((key: string) => {
      if (FormatByType[key].indexOf(format) > -1) {
        resultType = key;
      }
    });
    return resultType;
  };

  export const Format = Object.values(FormatByType).join();