import React, { FC, memo, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Bars } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { mutation } from '@src/adapters';
import { CreateArContext } from '@src/context';
import { CREATE_AR_FORM_MODE, ITEM_TYPE, SCENARIO } from '@src/core/types';
import Button from '@ui-kit/button';
import { colors } from '@ui-kit/theme';

import ScreenAddItem from './components/screen-add-item';
import SelectApp from './components/screen-select-app';
import SelectScenario from './components/screen-select-scenario';
import {
  Actions,
  Loading,
  LoadingWrapper,
  StyledBreadCrumbs,
  Text,
  Title,
} from './create-ar.styles';

interface CreateArProps {}

export const CreateAr: FC<CreateArProps> = memo(() => {
  const { t } = useTranslation();
  const createArContext = useContext(CreateArContext);
  const navigate = useNavigate();

  const [mutateDataItem, resultMutateDataItem] = useMutation(
    mutation.dataItem.createOneDataItem,
    {
      onCompleted: (data) => {
        if (data?.errors?.length > 0) {
          toast.error('Произошла ошибка', {
            position: toast.POSITION.TOP_RIGHT,
          });
          return;
        }
        toast.success('Сцена успешно создана. Ожидайте', {
          position: toast.POSITION.TOP_RIGHT,
        });
      },
      onError: (data) => {
        toast.error('Произошла ошибка', {
          position: toast.POSITION.TOP_RIGHT,
        });
      },
    }
  );
  const [mutateEditDataItem, resultMutateEditDataItem] = useMutation(
    mutation.dataItem.updateDataItem
  );

  const loading =
    resultMutateDataItem.loading || resultMutateEditDataItem.loading;

  const handleSelectApp = (value: any) => {
    createArContext.onAppChange(value);
    createArContext.onDataItemChange((dataItem) => {
      return { ...dataItem, applicationId: value.id };
    });
    createArContext.onNext();
  };

  const handleSubmit = () => {
    if (createArContext.mode === CREATE_AR_FORM_MODE.CREATE) {
      mutateDataItem({
        variables: {
          type: ITEM_TYPE.IMAGE,
          ...createArContext.dataItem,
          triggerName: createArContext.dataItem?.triggerFile?.name,
          applicationId: createArContext.app?.id,
          scale: Number(createArContext.dataItem?.scale),
          offsetX:Number(createArContext.dataItem?.offsetX),
          offsetY:Number(createArContext.dataItem?.offsetY),
          offsetZ:Number(createArContext.dataItem?.offsetZ),
        },
      });
    }

    if (createArContext.mode === CREATE_AR_FORM_MODE.EDIT) {
      mutateEditDataItem({
        variables: {
          ...createArContext.dataItem,
          thumbnailFile:
            typeof createArContext.dataItem?.thumbnailFile === 'string'
              ? undefined
              : createArContext.dataItem?.thumbnailFile,
          dataItemId: createArContext?.dataItem?.id,
        },
      });
      toast.success('Сцена успешно отредактирована. Ожидайте', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const renderScreen = () => {
    switch (createArContext.currentStepIndex) {
      case 0:
        return <SelectApp onNextClick={handleSelectApp} />;
      case 1:
        return (
          <SelectScenario
            onNextClick={(scenario) => {
              createArContext.onDataItemChange((dataItem) => {
                return scenario !== dataItem?.scenery
                  ? {
                      scenery: scenario,
                    }
                  : {
                      ...dataItem,
                      scenery: scenario,
                    };
              });
              createArContext.onNext();
            }}
            onPrevClick={() => {
              createArContext.onPrev();
            }}
            scenario={
              (createArContext?.dataItem?.scenery as SCENARIO) || undefined
            }
            scenaries={createArContext.app?.scenaries}
          />
        );
      case 2:
        return (
          <ScreenAddItem
            onPrevClick={createArContext.onPrev}
            onNextClick={handleSubmit}
          />
        );
      default:
        return null;
    }
  };

  const breadCrumbs = useMemo(() => {
    if (createArContext.currentStepIndex) {
      const list = [];

      if (createArContext.currentStepIndex > 0) {
        list.push({
          label: createArContext.app?.name ?? '',
          value: createArContext.app?.id.toString() ?? '',
        });
      }
      if (createArContext.currentStepIndex > 1) {
        list.push({
          label: t(`common.scenario.${createArContext?.dataItem?.scenery}`),
          value: createArContext?.dataItem?.scenery || '',
        });
      }

      return list;
    }

    return;
  }, [
    createArContext.currentStepIndex,
    createArContext.app,
    createArContext?.dataItem?.scenery,
    t,
  ]);

  if (loading) {
    return (
      <Loading>
        <LoadingWrapper>
          <Bars
            height="100"
            width="100"
            color={colors.background.yellow}
            ariaLabel="loading"
          />
        </LoadingWrapper>
        <div>
          <Title>{t('createAr.loadingConvertationTitle')}</Title>
          <Text>{t('createAr.loadingConvertationDescription')}</Text>
        </div>
      </Loading>
    );
  }

  const createSuccess =
    !resultMutateDataItem.loading &&
    !resultMutateDataItem.error &&
    resultMutateDataItem.data;
  const editSuccess =
    !resultMutateEditDataItem.loading &&
    !resultMutateEditDataItem.error &&
    resultMutateEditDataItem.data;

  if (createSuccess || editSuccess) {
    return (
      <>
        <Title>
          {createArContext.mode === CREATE_AR_FORM_MODE.EDIT &&
            t('createAr.editSuccess')}
          {createArContext.mode === CREATE_AR_FORM_MODE.CREATE &&
            t('createAr.createSuccess')}
        </Title>
        <Text>{t('common.continue')}</Text>
        <Actions>
          <Button
            variant="outline"
            onClick={() => window.location.reload()}
            text={t('createAr.createAr')}
          />
          <Button
            onClick={() => navigate('/media', { replace: true })}
            variant="dark"
            text={t('createAr.successAction')}
          />
        </Actions>
      </>
    );
  }

  return (
    <>
      {breadCrumbs && <StyledBreadCrumbs items={breadCrumbs} />}
      {renderScreen()}
    </>
  );
});
