import React, { FC, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SCENARIO } from '@src/core/types';
import Button from '@ui-kit/button';
import Icons from '@ui-kit/icons';
import Info from '@ui-kit/info';

import {
  ActionsRow,
  Buttons,
  InfoWrapper,
  Root,
  StyledImageContainer,
  Switcher,
  Title,
} from './screen-select-scenario.styles';

interface SelectScenarioProps {
  scenario?: SCENARIO;
  scenaries?: string[];
  onNextClick: (app: any) => void;
  onPrevClick: () => void;
}

const tabs = [
  {
    value: SCENARIO.BY_IMAGE,
    content: <Icons.ByImage size={32} />,
  },
  {
    value: SCENARIO.BY_OBJECT,
    content: <Icons.ByObject size={32} />,
  },
  {
    value: SCENARIO.BY_GEO,
    content: <Icons.ByGeo size={32} />,
  },
  {
    value: SCENARIO.BY_BODYPART,
    content: <Icons.ByBodypart size={32} />,
  },
  {
    value: SCENARIO.IN_SPACE,
    content: <Icons.BySpace size={32} />,
  },
];

export const SelectScenario: FC<SelectScenarioProps> = memo((props) => {
  const { onNextClick, onPrevClick, scenario, scenaries } = props;
  const [currentTab, setCurrentTab] = useState<SCENARIO>(
    scenario || SCENARIO.BY_IMAGE
  );
  const { t } = useTranslation();

  return (
    <Root>
      <Title>{t(`createAr.selectScenario.title.${currentTab}`)}</Title>

      <Switcher
        tabs={
          scenaries
            ? tabs.map((it) => {
                return { ...it, disabled: !scenaries.includes(it.value) };
              })
            : tabs
        }
        info={t(`createAr.selectScenario.description.${currentTab}`)}
        content={
          <StyledImageContainer
            style={{ backgroundImage: 'url(/ar_sample.gif)' }}
          />
        }
        onTabChange={(value: React.SetStateAction<string>) => {
          setCurrentTab(value as SCENARIO);
        }}
        currentTab={currentTab}
      />

      <ActionsRow>
        <InfoWrapper>
          <Info
            text={
              // currentTab === SCENARIO.BY_IMAGE &&
              t('createAr.selectScenario.tip')
            }
          />
        </InfoWrapper>
        <Buttons>
          <Button
            variant="outline"
            text={t('common.back')}
            onClick={onPrevClick}
          />
          <Button
            disabled={!currentTab}
            text={t('common.next')}
            variant="dark"
            onClick={() => onNextClick(currentTab)}
          />
        </Buttons>
      </ActionsRow>
    </Root>
  );
});
