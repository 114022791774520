import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { queries } from '@src/adapters';
import { AvailableAppsResponse } from '@src/adapters/apps';
import { APP_TYPE } from '@src/core/types';
import { arrayFill } from '@src/core/utils';
import {
  getChoice,
  saveChoice,
} from '@src/pages/create-ar/components/screen-select-app/screen-select-app.utils';
import Button from '@ui-kit//button';
import Checkbox from '@ui-kit//checkbox';
import Icons from '@ui-kit//icons';
import Info from '@ui-kit//info';

import { InfoRadio } from '../info-radio';
import {
  ActionsRow,
  ContentDescription,
  ContentTitle,
  InfoWrapper,
  Label,
  LoadingWrapper,
  Root,
  Row,
  Switcher,
  Title,
  Value,
} from './screen-select-app.styles';

interface SelectAppProps {
  onNextClick: (app: any) => void;
}

const LOADER = arrayFill({ arr: [], count: 2 });
const Loader = () => (
  <>
    {LOADER.map((e, i) => (
      <div key={`loader~${i}`}>
        <LoadingWrapper key={`loader-${i}`} style={{ marginBottom: 20 }}>
          <Skeleton height={32} width={32} circle style={{ marginRight: 10 }} />
          <Skeleton height={20} width={200} />
        </LoadingWrapper>
        <LoadingWrapper key={`loader-${i}.${i}`} style={{ marginBottom: 20 }}>
          <Skeleton height={32} width={32} circle style={{ marginRight: 10 }} />
          <Skeleton height={20} width={80} />
        </LoadingWrapper>
      </div>
    ))}
  </>
);

export const SelectApp: FC<SelectAppProps> = memo((props) => {
  const { onNextClick } = props;
  const [checked, setChecked] = useState<boolean>(!!getChoice());
  const [currentTab, setCurrentTab] = useState<string>(
    getChoice()?.currentTab || APP_TYPE.PUBLIC
  );
  const [currentApp, setCurrentApp] = useState<any | undefined>(); // TODO APP TYPE
  const navigate = useNavigate();
  const { t } = useTranslation();

  const LINK_APP = useMemo(
    () => ({
      label: t('common.connectApp'),
      id: 0,
      isEmpty: true,
    }),
    [t]
  );

  const { loading, data } = useQuery<AvailableAppsResponse>(
    queries.app.getApps
  );

  useEffect(() => {
    if (currentApp) {
      return;
    }
    const lsApp = getChoice();
    if (!lsApp) {
      return;
    }

    const availableApp = (data?.availableApplications || []).find(
      ({ id }) => id === lsApp?.appId
    );

    if (availableApp) {
      setCurrentApp(availableApp);
    }
  }, [data]);

  const handleSaveChoice = useCallback((appId: number, currentTab: string) => {
    saveChoice({
      appId,
      currentTab,
    });
  }, []);

  const handleSaveSelection = useCallback(() => {
    setChecked((value) => {
      const nextVal = !value;
      if (nextVal && currentApp?.id) {
        handleSaveChoice(currentApp?.id, currentTab);
      } else {
        saveChoice();
      }

      return nextVal;
    });
  }, [currentApp]);

  const infoData = useMemo(() => {
    if (APP_TYPE.EXPORT === currentTab) {
      return (
        <div style={{ whiteSpace: 'pre-line' }}>
          {t(`createAr.selectApp.info.${currentTab}`)}
        </div>
      );
    }

    if (loading) {
      return <Loader />;
    }

    const infoData = (data?.availableApplications ?? [])
      .filter(({ isPublic }) =>
        currentTab === APP_TYPE.PUBLIC ? isPublic : !isPublic
      )
      .map(({ name, id, thumbnailPath }) => ({
        id,
        label: name,
        thumbnail: thumbnailPath,
      }));

    return (
      <InfoRadio
        items={[LINK_APP, ...infoData]}
        initialValue={currentApp?.id}
        onChange={(item) => {
          if (!item?.id) {
            navigate('/me');
          }
          setCurrentApp(
            data?.availableApplications.find(({ id }) => id === item?.id)
          );
          if (checked && item?.id) {
            handleSaveChoice(item?.id, currentTab);
          }
        }}
      />
    );
  }, [loading, data, currentTab, currentApp]);

  const contentApp = useMemo(() => {
    const app = currentApp;
    if (!app) return;

    const items = [
      {
        value: app.name,
        label: t('app.name'),
      },
      {
        value: (
          <>
            {app.platforms.map((platform: string) => (
              <div key={platform}>{platform}</div>
            ))}
          </>
        ),
        label: t('app.platforms'),
      },
      {
        value: (
          <>
            {app.scenaries.map((scenario: string) => (
              <div key={scenario}>{t(`common.scenario.${scenario}`)}</div>
            ))}
          </>
        ),
        label: t('app.sceneries'),
      },
      {
        value: app.contentCount,
        label: t('app.sceneCount'),
      },
    ];

    return items.map((item, i) => (
      <Row key={`content-${item.value}-${i}}`}>
        <Label>{item.label}</Label>
        <Value>{item.value}</Value>
      </Row>
    ));
  }, [currentApp]);

  return (
    <Root>
      <Title>{t(`createAr.selectApp.title.${currentTab}`)}</Title>

      <Switcher
        tabs={[
          {
            value: APP_TYPE.PUBLIC,
            content: <Icons.Public size={32} />,
          },
          {
            value: APP_TYPE.PRIVATE,
            content: <Icons.Private size={32} />,
          },
          {
            value: APP_TYPE.EXPORT,
            content: <Icons.Export size={32} />,
          },
        ]}
        info={infoData}
        content={
          <>
            {currentTab === APP_TYPE.EXPORT &&
              t(`createAr.selectApp.content.${APP_TYPE.EXPORT}`)}
            {currentTab !== APP_TYPE.EXPORT && contentApp}
            {currentTab !== APP_TYPE.EXPORT && !contentApp && (
              <>
                <ContentTitle>{t('createAr.selectApp.action')}</ContentTitle>
                <ContentDescription>
                  {t('createAr.selectApp.actionDescription')}
                </ContentDescription>
              </>
            )}
          </>
        }
        onTabChange={(value: React.SetStateAction<string>) => {
          setCurrentTab(value);
          setCurrentApp(undefined);
        }}
        currentTab={currentTab}
      />

      <ActionsRow>
        <Checkbox
          checked={checked}
          onChange={handleSaveSelection}
          label={t('createAr.selectApp.saveChoice')}
        />
        <Button
          disabled={!currentApp}
          text={t('common.next')}
          variant="dark"
          onClick={() => {
            if (checked) {
              saveChoice({
                appId: currentApp?.id,
                currentTab,
              });
            }
            onNextClick(currentApp);
          }}
        />
      </ActionsRow>

      <InfoWrapper>
        <Info text={t(`createAr.selectApp.tip.${currentTab}`)} />
      </InfoWrapper>
    </Root>
  );
});
