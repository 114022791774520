import React, { FC, memo } from 'react';

import RangeSlider from '@ui-kit/range-slider';

import { Input, InputWrapper, Label, RangeWrapper } from './range-input.styles';

interface RangeInputProps {
  label: string;
  value: string;
  defaultValue?: string;
  onChange: (value: string) => void;
  min?: number;
  max?: number;
  step?: number;
}

export const RangeInput: FC<RangeInputProps> = memo((props) => {
  const {
    label,
    value,
    defaultValue = 0,
    onChange,
    min = 0,
    max = 10,
    step = 0.1,
  } = props;

  let regexNumber = new RegExp(/^-?[0-9]\d*(\.\d+)?$/);
  return (
    <InputWrapper>
      <Label>{label}</Label>
      <Input
        value={value}
     

        defaultValue={defaultValue}
        onChange={(e: any) => {
    
          onChange(e.target.value)}}
       
      />
      <RangeWrapper>
        <RangeSlider
          value={ (value==="-"||value==='') ? 0 : Number(value)}
          
          min={min}
          max={max}
          step={step}
          onChange={(value) => onChange(String(value))}
        />
      </RangeWrapper>
    </InputWrapper>
  );
});
