import React, { FC, MutableRefObject, memo, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TailSpin } from 'react-loader-spinner';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { DataPackage as DataPackageType } from '@src/adapters/data-item';
import Button from '@ui-kit/button';
import Popup from '@ui-kit/popup';
import { colors } from '@ui-kit/theme';

import { DialogWindow } from '../dialog-window';
import {
  Actions,
  Input,
  InputWrapper,
  Label,
  StyledFileUploader,
  Text,
} from './data-package.styles';

interface DataPackageDialogProps {
  mode?: 'create' | 'edit';
  isOpened: boolean;
  onClose: () => void;
  dataPackage?: DataPackageType;
  onSubmit: (
    data: DataPackageForm,
    fileRef: MutableRefObject<HTMLInputElement | null>
  ) => void;
  loading?: boolean;
}

export interface DataPackageForm {
  name: string;
  description: string;
  tags: string;
  thumbnailFile: string;
}

const dataPackageSchema = Yup.object<DataPackageForm>({
  name: Yup.string()
    .required()
    .transform((value) => value?.trim()),
  description: Yup.string()
    .required()
    .transform((value) => value?.trim()),
  tags: Yup.string(),
  thumbnailFile: Yup.string(),
});

export const DataPackage: FC<DataPackageDialogProps> = memo((props) => {
  const {
    isOpened,
    onClose,
    onSubmit,
    mode = 'create',
    dataPackage,
    loading,
  } = props;
  const [fileRef, setFileRef] = useState<any>();

  const {
    register,
    formState: { isValid },
    control,
    handleSubmit: handleSubmitHookForm,
    reset,
    watch,
  } = useForm<DataPackageForm>({
    resolver: yupResolver(dataPackageSchema),
    mode: 'all',
  });

  const thumbnailFile = watch('thumbnailFile');

  useEffect(() => {
    if (dataPackage) {
      reset({
        name: dataPackage.name,
        description: dataPackage.description,
        tags: dataPackage.tags ? dataPackage.tags.join() : '',
      });
    }
  }, [dataPackage]);

  const handleSubmit = (form: any) => {
    onSubmit(
      {
        ...form,
        ...(!!form.tags && {
          tags: form.tags?.split(/ |,/)?.filter(Boolean) || [],
        }),
      },
      fileRef
    );
  };

  const titleText =
    mode === 'edit' ? 'Редактирование пакета данных' : 'Создание пакета данных';
  const submitText = mode === 'edit' ? 'Сохранить' : 'Создать';

  return (
    <Popup variant="full" isOpened={isOpened} onClose={onClose}>
      <DialogWindow title={titleText} onClose={onClose}>
        <InputWrapper>
          <Label>Название</Label>
          <Input {...register('name')} name="name" />
        </InputWrapper>
        <InputWrapper>
          <Label>Описание</Label>
          <Text {...register('description')} />
        </InputWrapper>{' '}
        <InputWrapper>
          <Label>Тэги</Label>
          <Input {...register('tags')} />
        </InputWrapper>
        <InputWrapper>
          <Label>Логотип</Label>
          <Controller
            name="thumbnailFile"
            control={control}
            render={({ field }) => (
              <StyledFileUploader
                {...field}
                accept=".jpg,.jpeg,.png"
                imageUrl={
                  (!thumbnailFile && dataPackage?.thumbnailPath) || undefined
                }
                colorVariant="dark"
                onChange={(ref) => {
                  const files = ref.current?.files;
                  field.onChange(
                    (files && files.length && files[0].name) || undefined
                  );
                  setFileRef(ref);
                }}
              />
            )}
          />
        </InputWrapper>
        <Actions>
          {!loading && (
            <Button
              disabled={!isValid}
              onClick={handleSubmitHookForm(handleSubmit)}
              text={submitText}
              variant="dark"
            />
          )}
          {loading && (
            <TailSpin
              height="32"
              width="32"
              color={colors.background.yellow}
              ariaLabel="loading"
            />
          )}
        </Actions>
      </DialogWindow>
    </Popup>
  );
});
