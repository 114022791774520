import styled from 'styled-components';

import FileUploader from '@ui-kit/file-uploader';
import { colors, mixins, shape, typography } from '@ui-kit/theme';

export const Root = styled.div`
  ${mixins.flex({ horizontal: false })};
`;

export const InputWrapper = styled.div`
  color: ${colors.text.white};
  ${mixins.flex({
    verticalAlignment: 'flex-start',
    horizontalAlignment: 'flex-start',
  })};
  margin-bottom: 24px;
`;

export const Label = styled.div`
  margin-right: 12px;
  width: 30%;
  text-align: right;

  ${typography.regular16};
`;

export const Input = styled.input`
  border-radius: ${shape.rounding.S};
  border: 1px solid ${colors.text.white};
  background: none;
  padding: 4px;
  box-sizing: border-box;
  flex: 1;

  color: ${colors.text.white};
`;

export const Text = styled.textarea`
  border-radius: ${shape.rounding.S};
  border: 1px solid ${colors.text.white};
  background: none;
  padding: 4px;
  box-sizing: border-box;
  flex: 1;
  min-width: 90px;
  max-width: 270px;
  min-height: 80px;

  color: ${colors.text.white};
  ${typography.regular14};
`;

export const StyledFileUploader = styled(FileUploader)`
  flex: 1;
   min-width: 90px;
  max-width: 270px;
`;
