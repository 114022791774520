export enum SCENARIO {
  'BY_IMAGE' = 'by_image',
  'BY_GEO' = 'by_geo',
  'BY_HERO' = 'by_hero',
  'BY_BODYPART' = 'by_bodypart',
  'BY_OBJECT' = 'by_object',
  'IN_SPACE' = 'in_space',
}

export enum PLATFORM {
  IOS = 'ios',
  ANDROID = 'android',
  WEB = 'web',
}

export enum CREATE_AR_STEP {
  APP = 'APP',
  SCENARIO = 'SCENARIO',
  SETTINGS = 'SETTINGS',
}

export enum APP_TYPE {
  PUBLIC = 'public',
  PRIVATE = 'private',
  EXPORT = 'export',
}

export interface DataItem {
  scenery?: string;
  name?: string;
  type?: string;
  applicationId?: number;
  description?: string;
  thumbnailFile?: any;
  itemFile?: any;
  filePath?: any;
  dataPackageId?: number;
  triggerFile?: any;
  scale?: number|string;
  offsetX?: number|string;
  offsetY?: number|string;
  offsetZ?: number|string;
  actionUrl?: string;
  triggerName?: string;
  triggerLongtitude?: string;
  triggerLatitude?: string;
  triggerAltitude?: string;
  movableContent?: boolean;
  isAlpha?: boolean;
  createdAt?: string;
  id?: number;
}

export enum ITEM_TAB {
  TRIGGER = 'TRIGGER',
  UPLOAD = 'UPLOAD',
  SETTINGS = 'SETTINGS',
  NAME = 'NAME',
  DATA_PACKAGE = 'DATA_PACKAGE',
}

export enum ITEM_TYPE {
  IMAGE = 'image',
  OBJECT = 'object',
  VIDEO = 'video',
  WIDGET = 'widget',
}

export enum CREATE_AR_FORM_MODE {
  CREATE = 'create',
  EDIT = 'edit',
}
